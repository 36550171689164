<script setup>
import { HomeIcon, ClockIcon, CalendarIcon } from "@heroicons/vue/24/outline";
const { platform } = useRuntimeConfig().public;

const currentRoute = useRoute();
const settings = useSettings();
const items = [
  {
    name: "Inicio",
    icon: HomeIcon,
    to: {
      name: "profile",
    },
  },
  {
    name: "Cafetería",
    icon: h(
      "svg",
      {
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 490.7 490.7",
        "xml:space": "preserve",
      },
      [
        h("path", {
          fill: "currentColor",
          d: "M46.8 376.1h395.9c11.5 0 20.8-9.4 20.8-20.8 0-129.5-86.9-235.9-197.9-247.9v-22h17.7c11.5 0 20.8-9.4 20.8-20.8s-9.4-20.8-20.8-20.8h-77.1c-11.5 0-20.8 9.4-20.8 20.8s9.4 20.8 20.8 20.8h17.7v22C112.9 119.4 26 225.8 26 355.3c0 11.5 9.4 20.8 20.8 20.8zm198-228.1c92.7 0 168.8 82.3 177.1 187.5H67.7C77 230.3 152.1 148 244.8 148zM469.8 405.3h-449C9.3 405.3 0 414.7 0 426.1s9.4 20.8 20.8 20.8h448c11.5 0 21.9-9.4 21.9-20.8s-9.4-20.8-20.9-20.8z",
        }),
      ],
    ),
    to: {
      name: "establishment_uuid-shop",
      params: {
        establishment_uuid: settings.value.establishments[0].uuid,
      },
    },
  },
  {
    name: "Pedidos",
    icon: ClockIcon,
    to: {
      name: "history-orders",
    },
  },
];

const isActiveItem = (item) => {
  return currentRoute.name === item.name;
};
</script>

<template>
  <div
    class="fixed bottom-0 left-0 z-50 w-full bg-white ring-1 ring-slate-200 pt-3 pb-5-safe"
  >
    <div class="grid h-full max-w-lg grid-cols-3 mx-auto font-medium">
      <NuxtLink
        v-for="(item, itemKey) in items"
        :key="itemKey"
        type="button"
        class="inline-flex flex-col items-center justify-center px-5 group cursor-pointer"
        :to="item.to"
      >
        <component
          :is="item.icon"
          class="w-5 h-5 mb-2"
          :class="isActiveItem(item) ? 'text-primary-600' : 'text-black/80'"
        />
        <span
          class="text-xs"
          :class="isActiveItem(item) ? 'text-primary-600' : 'text-black/80'"
          >{{ item.name }}</span
        >
      </NuxtLink>
    </div>
  </div>
</template>
